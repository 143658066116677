import { Formik, Form, Field, ErrorMessage, FormikValues } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "react-phone-number-input/style.css";
import { countries } from "../../Countries/Countries";
// import { useState } from "react";
// import SubmitSuccess from "./SubmitSuccess";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

const FormDetail = ({ partner, buttonName, serviceType }: any) => {
  let navigate = useNavigate();
  // const [openSuccess, setOpenSuccess] = useState(false);
  console.log('partner is: '+ partner);

  interface myFormValues {
    firstName: string;
    lastName: string;
    workEmail: string;
    phoneNumber: string;
    companyName: string;
    jobTitle: string;
    companySize: string;
    country: string;
    hearAbout: string;
  }

  const initialValues: myFormValues = {
    firstName: "",
    lastName: "",
    workEmail: "",
    phoneNumber: "",
    companyName: "",
    jobTitle: "",
    companySize: "",
    country: "Nigeria",
    hearAbout: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("*Required"),
    lastName: Yup.string().required("*Required"),
    workEmail: Yup.string()
      .email("*Invalid email address")
      .required("*Required"),
    phoneNumber: Yup.string()
      .phone(undefined, true, "*Invalid Phone Number")
      .required(),
    companyName: Yup.string().required("*Required"),
    jobTitle: Yup.string().required("*Required"),
    companySize: Yup.string().required("*Required"),
    country: Yup.string().required("*Required"),
    hearAbout: Yup.string().required("*Required"),
  });

  const handleSubmit = (values: FormikValues, onSubmitProps: any) => {
    const {
      firstName,
      lastName,
      workEmail,
      phoneNumber,
      companyName,
      jobTitle,
      companySize,
      country,
      hearAbout,
    } = values;

    //url: `https://script.google.com/macros/s/AKfycbyvWIsl9pUw41bqFcl5YVcfads43c0TUA6PoNSVadBmhYxC8fbME7ZhgFriQP-fqbuV/exec?service_type=${serviceType}&first_name=${firstName}&last_name=${lastName}&email=${workEmail}&phone_no=${phoneNumber}&org_name=${companyName}&job_title=${jobTitle}&company_size=${companySize}&country=${country}&how_did_you_hear=${hearAbout}`,
    const config = {
      url: `https://api.omnibranches.com/v1/create-form?service_type=${serviceType}&first_name=${firstName}&last_name=${lastName}&email=${workEmail}&phone_no=${phoneNumber}&org_name=${companyName}&job_title=${jobTitle}&company_size=${companySize}&country=${country}&how_did_you_hear=${hearAbout}&partner=${partner}`,
      headers: {
        "Content-Type": "text/plain",
      },
    };

    const mailAPI = {
      from: "KlevaHR <hrms@klevahr.com>",
      to: ["sales@Klevahr.com"],
      domain: "hrms",
      subject: `${
        serviceType === "free_trial"
          ? "Free trial sign up"
          : "Customized version new lead"
      }`,
      text: "Texting",
      content: `<div className="h-14 rounded-md p-4 bg-slate-400 text-center"><p>${
        serviceType === "free_trial"
          ? "You have a new lead for KlevaHR Free Trial. Click the <a href='https://docs.google.com/spreadsheets/d/1gY-7IEoLP4lCqjgGk2QS8Vohtsh-KITVRbPO7WthujU/edit#gid=1116468243'>link</a> to view.<br /><br />You will need to refresh the sheet to see the updates via these steps:<br /><br />KlevaMenu ➡️ Refresh Sheet ➡️ Ok"
          : "You have a new lead for KlevaHR Customized Version. Click the <a href='https://docs.google.com/spreadsheets/d/1gY-7IEoLP4lCqjgGk2QS8Vohtsh-KITVRbPO7WthujU/edit#gid=2103143322'>link</a> to view.<br /><br />You will need to refresh the sheet to see the updates via these steps:<br /><br />KlevaMenu ➡️ Refresh Sheet ➡️ Ok"
      }<p><div>`,
    };

    const clientMailAPI = {
      from: "KlevaHR <hrms@klevahr.com>",
      to: [`${workEmail}`],
      domain: "hrms",
      subject: `${
        serviceType === "free_trial"
          ? "Your free trial is processing"
          : "Let’s customize your HR solution"
      }`,
      text: "Texting",
      content: `<div className="h-14 rounded-md p-4 bg-slate-400 text-center"><p>Dear ${firstName}, <br /><br />${
        serviceType === "free_trial"
          ? "We are thrilled to receive your request and can't wait to onboard your organization into our HR solution which we are confident will increase your workforce productivity.<br /> Our sales team will contact you shortly to speed things up."
          : "We are thrilled to receive your request and can't wait to take your requirements for customization of our HR solution to meet your HR needs.<br />Our sales team will contact you shortly to speed things up."
      }<br /><br />For more information or assistance, please contact us at sales@Klevahr.com.</p> </div>`,
    };

    axios(config)
      .then((res: any) => {
        if (res.request.status === 200) {
          // setOpenSuccess(true);
          // formik.resetForm();
          navigate("/free-trial-success-page");
        }
        axios
          .post("https://api.omnibranches.com/v1/send-mail", mailAPI)
          .then((res: any) => {})
          .catch((err: any) => {
            // console.log(err);
          });
        axios
          .post("https://api.omnibranches.com/v1/send-mail", clientMailAPI)
          .then((res: any) => {})
          .catch((err: any) => {
            // console.log(err);
          });
      })

      .catch((err: any) => {
        // console.log(err);
      });
    // setOpenSuccess(true);

    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-5 ">
            <div className="grid md:grid-cols-formFields mobile:grid-cols-mobileFormFields md:gap-x-10 gap-y-5 ">
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="firstName"
                    className="text-tertiary font-primary font-medium"
                  >
                    First name
                  </label>
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="firstName"
                  id="firstName"
                  type="text"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="lastName"
                    className="text-tertiary font-primary font-medium"
                  >
                    Last name
                  </label>
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="lastName"
                  id="lastName"
                  type="text"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="workEmail"
                    className="text-tertiary font-primary font-medium"
                  >
                    Work email
                  </label>
                  <ErrorMessage
                    name="workEmail"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="workEmail"
                  id="workEmail"
                  type="text"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="phoneNumber"
                    className="text-tertiary font-primary font-medium"
                  >
                    Phone number
                  </label>
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="phoneNumber"
                  id="phoneNumber"
                  type="text"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="companyName"
                    className="text-tertiary font-primary font-medium"
                  >
                    Company name
                  </label>
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="companyName"
                  id="companyName"
                  type="text"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="jobTitle"
                    className="text-tertiary font-primary font-medium"
                  >
                    Job title
                  </label>
                  <ErrorMessage
                    name="jobTitle"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="jobTitle"
                  id="jobTitle"
                  type="text"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="companySize"
                    className="text-tertiary font-primary font-medium"
                  >
                    Company size
                  </label>
                  <ErrorMessage
                    name="companySize"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="companySize"
                  id="companySize"
                  as="select"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                >
                  <option value="" label="What is your company size?" />
                  <option value="20 to 50"> 20 to 50</option>
                  <option value="51 to 100">51 to 100</option>
                  <option value="101 to 500">101 to 500</option>
                  <option value="501 to 1000">501 to 1000</option>
                  <option value="More than 1000">More than 1000</option>
                </Field>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="country"
                    className="text-tertiary font-primary font-medium"
                  >
                    Country
                  </label>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="country"
                  id="country"
                  as="select"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary  "
                >
                  {countries.map((country) => (
                    // <option
                    //   key={country.name}
                    //   value={country.code && country.name}
                    //   className="max-w-[340px]"
                    // >
                    //   {country.name}
                    // </option>
                    <>
                      <option
                        defaultValue={"Nigeria"}
                        className="max-w-[340px]"
                      >
                        Nigeria
                      </option>
                      <option
                        key={country.code}
                        value={country.name}
                        className="max-w-[340px]"
                      >
                        {country.name}
                      </option>
                    </>
                  ))}
                </Field>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="hearAbout"
                    className="text-tertiary font-primary font-medium"
                  >
                    How did you hear about us?
                  </label>
                  <ErrorMessage
                    name="hearAbout"
                    component="div"
                    className="text-red-500 text-xs font-primary"
                  />
                </div>
                <Field
                  name="hearAbout"
                  id="hearAbout"
                  as="select"
                  className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                >
                  <option value="" label="Select" />
                  <option value="Facebook">Facebook</option>
                  <option value="Friends/Colleagues">Friends/Colleagues</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Linkedin">Linkedin</option>
                  <option value="Twitter">Twitter</option>
                  <option value="Others">Others</option>
                </Field>
              </div>
            </div>
            <p className=" pb-5">
              By clicking the button below, you agree to our Terms of use and
              consent to our Privacy policy.
            </p>
            <div className="flex justify-end mobile:sticky mobile:bottom-0 border-t border-solid border-secondary border-opacity-30 mobile:bg-white pt-4 mobile:py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="h-11 bg-primary text-white rounded-md font-primary font-bold w-40 text-lg disabled:cursor-not-allowed disabled:bg-gray-500"
              >
                {buttonName}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {/* <SubmitSuccess
        open={openSuccess}
        handleclose={() => {
          setOpenSuccess(false);
          navigate("/");
        }}
      /> */}
    </div>
  );
};

export default FormDetail;
