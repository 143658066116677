import { useState } from "react";
import { NavLink } from "react-router-dom";

const FormsNav = () => {
  const [toggle, setToggle] = useState<Boolean>(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const navLinkStyles = ({ isActive }: { isActive: any }) => {
    return {
      color: isActive ? "#649AE9" : "#475569",
    };
  };

  return (
    <nav className=" bg-white fixed top-0  shadow-nav z-[999] w-full">
      <div className="container z-30 bg-white">
        <div className="bg-white w-full flex justify-between items-center h-[67px] md:h-[90px]">
          <NavLink to="/">
            <img src="./images/KlevaHRlogo.svg" className="h-8 " alt="Logo" />
          </NavLink>

          <div
            className="flex justify-center items-center w-8 h-8 cursor-pointer md:hidden"
            onClick={handleToggle}
          >
            <div
              className={`w-7 h-1 before:bg-primary after:bg-primary rounded-full before:content-[''] after:content-[''] before:absolute after:absolute before:rounded-full after:rounded-full before:w-7  after:w-7 before:h-1 after:h-1 transition after:transition before: ease-in-out after:ease-in-out before:duration-500 after:duration-500
                ${
                  !toggle
                    ? " bg-primary before:-translate-y-2.5 after:translate-y-2.5"
                    : " bg-transparent  before:rotate-45 after:-rotate-45"
                }`}
            ></div>
          </div>

          <div
            className={` mobile:absolute mobile:w-full  mobile:bg-white mobile:right-0 mobile:transition-all mobile:ease-in-out mobile:-z-10 mobile:shadow-nav
              ${
                toggle
                  ? " mobile:top-16  mobile:duration-700  mobile:border-t mobile:border-opacity-30  mobile:border-solid"
                  : " mobile:-top-64  mobile:duration-500 "
              }`}
          >
            <ul className="mobile:bg-white  mobile:h-full mobile:flex mobile:flex-col  mobile:justify-center mobile:p-5 mobile:shadow-nav  md:h-16 md:flex md:items-center md:pt-0 md:z-20 text-sm md:space-x-8 mobile:space-y-[25px]">
              <NavLink to="/free-trial" style={navLinkStyles}>
                <li className="cursor-pointer font-bold text-base font-primary ">
                  Free trial
                </li>
              </NavLink>
              <NavLink to="/schedule-demo" style={navLinkStyles}>
                <li className="cursor-pointer font-bold text-base font-primary ">
                  Schedule demo
                </li>
              </NavLink>
              <NavLink to="/custom-request" style={navLinkStyles}>
                <li className="cursor-pointer font-bold text-base font-primary ">
                  Custom request
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default FormsNav;
