import React from "react";
import { useNavigate } from "react-router-dom";
import SubmitSuccess from "../components/Forms/FormStructure/SubmitSuccess";

const FreetrialSuccessPage = () => {
  let navigate = useNavigate();

  return (
    <>
      <SubmitSuccess open={true} handleclose={() => navigate("/")} />
    </>
  );
};

export default FreetrialSuccessPage;
