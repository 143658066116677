import { FormikValues } from "formik";

interface Props {
  hasPrevious?: boolean;
  onBackClick: (values: FormikValues) => void;
  isLastStep: boolean;
  isSubmitting: boolean;
}

const FormNavigation = (props: Props) => {
  return (
    <div className="flex justify-between pt-5 mobile:sticky mobile:bottom-0 pb-5 bg-white space-x-2">
      {!props.hasPrevious && (
        <button
          type="button"
          className="cursor-not-allowed w-40 h-11 border border-primary rounded-md opacity-50 text-primary md:text-lg font-primary font-bold text-sm"
        >
          Back
        </button>
      )}
      {props.hasPrevious && (
        <button
          type="button"
          onClick={props.onBackClick}
          className="w-40 h-11 border border-primary rounded-md text-primary md:text-lg font-primary font-bold text-sm"
        >
          Back
        </button>
      )}
      <button
        type="submit"
        disabled={props.isSubmitting}
        className="w-40 h-11 bg-primary  rounded-md text-white md:text-lg font-primary font-bold text-sm disabled:bg-gray-500"
      >
        {props.isLastStep ? "Submit" : "Next"}
      </button>
    </div>
  );
};

export default FormNavigation;
