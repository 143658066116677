import React from "react";
import Footer from "../../components/Footer/Footer";
import { NavLink } from "react-router-dom";

const LandingPage2 = () => {
  return (
    <div>
      <div className="bg-slanted bg-no-repeat bg-cover md:min-h-[750px]">
        <div className="flex items-center flex-col">
          <NavLink to="/">
            <img
              src="./images/KlevaHRlogo.svg"
              className="h-[50px] w-[161px] mt-[4rem] mb-10"
              alt="KlevaHr logo"
            />
          </NavLink>
          <div className="text-center">
            <span className="font-normal font-secondary mb-4 text-[30px]">
              You have the{" "}
            </span>
            <span className="font-medium text-[#649AE9] font-secondary text-[30px]">
              Human Resource.
            </span>
          </div>
          <div className="text-center">
            <span className="font-normal font-secondary mb-4 text-[30px]">
              We have the{" "}
            </span>
            <span className="font-medium text-[#649AE9] font-secondary text-[30px]">
              Management System.
            </span>
          </div>
          <div className="mb-[40px] text-center">
            <span className="font-normal font-secondary mb-4 text-[30px]">
              Let's fit them together for{" "}
            </span>
            <span className="font-normal text-[#649AE9] font-secondary text-[30px]">
              Productivity.
            </span>
          </div>
          <div>
            <img
              src="Laptop.png"
              alt="no img"
              className="md:h-[547px] h-[300px] w-[964px]"
            />
          </div>
        </div>
      </div>
      <div className="flex ml-[1rem] items-center flex-col">
        <div className="text-[#475569]  ml-[1rem] font-normal font-secondary mt-[68px] md:text-[30px] text-[24px] mb-7">
          In 30 minutes, you get to see how KlevaHR will:
        </div>
        <div className="grid w-[100%] md:w-[80%] md:gap-x-8 md:gap-y-8 gap-y-5 md:grid-cols-2">
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              <img
                src="Driveperformance.svg"
                alt="no img"
                className=" w-[80px]"
              />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Drive </span>your workforce
              performance through goal/task tracking & evaluation
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              <img src="Automate.svg" alt="no img" className=" w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Automate </span> your payroll
              with few clicks & generate pay slip on demand
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              <img src="Eliminate.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Eliminate </span>your manual
              employee onboarding processes with automation
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              {" "}
              <img src="Aggregate.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Aggregate </span> all your
              employee records into a single source of truth
            </span>
          </div>
        </div>
        <NavLink to="/schedule-demo">
          <div className="my-[48px] flex justify-center font-secondary  md:text-[18px] text-[16px] items-center text-white rounded-3xl bg-[#649AE9] w-[200px] h-[46px] cursor-pointer">
            I want a demo
          </div>
        </NavLink>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage2;
