import React from "react";
import ScheduleSubmitSuccess from "../components/Forms/ScheduleStructure/ScheduleSubmitSuccess";
import { useLocation } from "react-router-dom";

const SuccessPage = () => {
  let open = true;
  const { state } = useLocation();

  return (
    <>
      <ScheduleSubmitSuccess
        open={open}
        date={state?.date}
        time={state?.time}
      />
    </>
  );
};

export default SuccessPage;
