import React from "react";

interface Props {
  name: string;
  filled?: boolean;
}

const Button = ({ name, filled }: Props) => {
  return (
    <button
      className={`w-40 h-11 rounded-3xl font-primary font-bold ${
        filled
          ? "bg-primary text-white"
          : "border border-solid border-primary bg-white text-primary hover:bg-primary hover:text-white transition duration-500 ease-in-out"
      }`}
    >
      {name}
    </button>
  );
};

export default Button;
