import React, { useState } from "react";
import { modules } from "./shared";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

const ModulesMobile = () => {
  interface Module {
    id: number;
    title: {
      topic: string;
      icon: string;
    };
    content: {
      title: string;
      description: string;
      image: string;
    };
  }

  const [clicked, setClicked] = useState<number | null>(null);

  const toggle = (index: number) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <div className="container md:hidden">
      <div className="flex flex-col">
        {modules.map((module: Module, idx: number) => (
          <div key={module.id} className="">
            <div
              className={`flex items-center justify-between py-6 cursor-pointer  ${
                module.id !== 1 &&
                "border-solid border-t-[1px] border-opacity-30 border-secondary "
              }`}
              onClick={() => toggle(idx)}
            >
              <div className="flex items-center space-x-[5px] ">
                <img src={module.title.icon} alt="icon" className="w-[22px]" />
                <h1 className="font-primary font-extrabold text-secondary text-sm">
                  {module.title.topic}
                </h1>
              </div>
              <div
                className={`${clicked === idx && "rotate-180 duration-500"}`}
              >
                <MdOutlineKeyboardArrowUp size="25px" color="#649AE9" />
              </div>
            </div>
            <div
              className={`flex flex-col justify-center transition-all duration-700 overflow-hidden ${
                clicked === idx
                  ? "h-auto ease-faq-open max-h-screen"
                  : "max-h-0 ease-faq-close"
              }`}
            >
              <h1 className="font-secondary font-bold text-[27px] text-secondary text-center">
                {module.content.title}
              </h1>
              <p className="font-primary font-medium text-base text-secondary text-center">
                {module.content.description}
              </p>
              <img src={module.content.image} alt={module.title.topic} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModulesMobile;
