// export const times = [
//   { time: "9.00 am", timeFrame: "9.00 am - 10.00 am", id: 1 },
//   { time: "10.00 am", timeFrame: "10.00 am - 11.00 am", id: 2 },
//   { time: "11.00 am", timeFrame: "11.00 am - 12.00 am", id: 3 },
//   { time: "12.00 pm", timeFrame: "12.00 pm - 1.00 pm", id: 4 },
//   { time: "1.00 pm", timeFrame: "1.00 pm - 2.00 pm", id: 5 },
//   { time: "2.00 pm", timeFrame: "2.00 pm - 3.00 pm", id: 6 },
//   { time: "3.00 pm", timeFrame: "3.00 pm - 4.00 pm", id: 7 },
//   { time: "4.00 pm", timeFrame: "4.00 pm - 5.00 pm", id: 8 },
//   { time: "5.00 pm", timeFrame: "5.00 pm - 6.00 pm", id: 9 },
// ];

export const times = [
  { time: "9.00 am", timeFrame: "9.00 am - 9.15 am", id: -1 },
  { time: "9.15 am", timeFrame: "9.15 am - 9.30 am", id: 1 },
  { time: "9.30 am", timeFrame: "9.30 am - 9.45 am", id: 2 },
  { time: "9.45 am", timeFrame: "9.45 am - 10.00 am", id: 3 },
  { time: "10.00 am", timeFrame: "10.00 am - 10.15 am", id: 4 },
  { time: "10.15 am", timeFrame: "10.15 am - 10.30 am", id: 5 },
  { time: "10.30 am", timeFrame: "10.30 am - 10.45 am", id: 6 },
  { time: "10.45 am", timeFrame: "10.45 am - 11.00 am", id: 7 },
  { time: "11.00 am", timeFrame: "11.00 am - 11.15 am", id: 8 },
  { time: "11.15 am", timeFrame: "11.15 am - 11.30 am", id: 9 },
  { time: "11.30 am", timeFrame: "11.30 am - 11.45 am", id: 10 },
  { time: "11.45 am", timeFrame: "11.45 am - 12.00 am", id: 11 },
  { time: "12.00 pm", timeFrame: "12.00 pm - 12.15 pm", id: 12 },
  { time: "12.15 pm", timeFrame: "12.15 pm - 12.30 pm", id: 13 },
  { time: "12.30 pm", timeFrame: "12.30 pm - 12.45 pm", id: 14 },
  { time: "12.45 pm", timeFrame: "12.45 pm - 1.00 pm", id: 15 },
  { time: "1.00 pm", timeFrame: "1.00 pm - 1.15 pm", id: 16 },
  { time: "1.15 pm", timeFrame: "1.15 pm - 1.30 pm", id: 17 },
  { time: "1.30 pm", timeFrame: "1.30 pm - 1.45 pm", id: 18 },
  { time: "1.45 pm", timeFrame: "1.45 pm - 2.00 pm", id: 19 },
  { time: "2.00 pm", timeFrame: "2.00 pm - 2.15 pm", id: 20 },
  { time: "2.15 pm", timeFrame: "2.15 pm - 2.30 pm", id: 21 },
  { time: "2.30 pm", timeFrame: "2.30 pm - 2.45 pm", id: 22 },
  { time: "2.45 pm", timeFrame: "2.45 pm - 3.00 pm", id: 23 },
  { time: "3.00 pm", timeFrame: "3.00 pm - 3.15 pm", id: 24 },
  { time: "3.15 pm", timeFrame: "3.15 pm - 3.30 pm", id: 25 },
  { time: "3.30 pm", timeFrame: "3.30 pm - 3.45 pm", id: 26 },
  { time: "3.45 pm", timeFrame: "3.45 pm - 4.00 pm", id: 27 },
  { time: "4.00 pm", timeFrame: "4.00 pm - 4.15 pm", id: 28 },
  { time: "4.15 pm", timeFrame: "4.15 pm - 4.30 pm", id: 29 },
  { time: "4.30 pm", timeFrame: "4.30 pm - 4.45 pm", id: 30 },
  { time: "4.45 pm", timeFrame: "4.45 pm - 5.00 pm", id: 31 },
  { time: "5.00 pm", timeFrame: "4.45 pm - 5.00 pm", id: 32 },
  // { time: "5.15 pm", timeFrame: "5.15 pm - 5.30 pm", id: 33 },
  // { time: "5.30 pm", timeFrame: "5.30 pm - 5.45 pm", id: 34 },
  // { time: "5.45 pm", timeFrame: "5.45 pm - 6.00 pm", id: 35 },
  
];
