import {
  FaLinkedinIn,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <div className="bg-footer bg-cover bg-left-top bg-no-repeat">
      <div className="container pt-12 md:pt-16">
        <div className="flex flex-col md:flex-row md:items-center md:justify-around mb-6 md:mb-20">
          <div className="space-y-2 mb-8 md:mb-0">
            <h1 className="font-secondary font-extrabold text-white text-[27px] md:text-[32px]">
              Ready to try KlevaHR?
            </h1>
            <p className="text-base font-primary text-white ">
              1 month free trial. No debit card required.
            </p>
          </div>
          <NavLink to="/free-trial">
            <button className="w-40 h-11 rounded-3xl font-primary font-bold border border-solid border-primary bg-white text-primary transition duration-500 ease-in-out">
              Yes please
            </button>
          </NavLink>
        </div>
        <hr />
        <div className="mt-7 flex flex-col items-center mobile:space-y-8 md:flex-row pb-5 w-full md:justify-between">
          <div className="flex items-center justify-center space-x-5">
            <img
              src="images/KlevaHRlogoWhite.svg"
              alt="Logo"
              className="mb-4"
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://web.facebook.com/KlevaHR"
            >
              <FaFacebookF color="white" size="23" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/KlevaHR"
            >
              <FaTwitter color="white" size="23" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/klevahr"
            >
              <FaLinkedinIn color="white" size="23" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/klevahr/"
            >
              <FaInstagram color="white" size="23" />
            </a>
          </div>
          {/* <div className="flex flex-col items-center mobile:space-y-5 md:flex-row-reverse w-full"> */}
          <div className="flex space-x-5 md:space-x-12">
            <NavLink
              to="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-base md:text-lg font-primary text-white font-bold">
                Terms of use
              </p>
            </NavLink>
            <NavLink
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-base md:text-lg font-primary text-white font-bold">
                Privacy policy
              </p>
            </NavLink>
          </div>
          <p className="text-base md:text-lg font-primary text-white text-center">
            {`Copyright ⓒ ${date} KlevaHR. All rights reserved`}
          </p>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
