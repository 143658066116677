import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { modules } from "./shared";

const ModulesDesktop = () => {
  interface Module {
    id: number;
    title: {
      topic: string;
      icon: string;
    };
    content: {
      title: string;
      description: string;
      image: string;
      alt: string;
    };
  }

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="container hidden md:block ">
      <div className="w-full flex flex-col items-center pt-11">
        <TabContext value={value}>
          <div className="rounded-[27px] bg-[#E9EEF5]">
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile  
            >
              {modules.map((module: Module, idx: number) => (
                <Tab
                  key={idx}
                  icon={
                    <img src={module.title.icon} alt={module.content.alt} />
                  }
                  iconPosition="start"
                  label={module.title.topic}
                  value={`${module.id}`}
                />
              ))}
            </TabList>
          </div>

          {modules.map((module: Module, idx: number) => (
            <TabPanel value={`${module.id}`} key={idx}>
              <div
                className={`flex flex-col justify-center transition-all duration-700 overflow-hidden space-y-2`}
              >
                <h1 className="font-secondary font-bold text-[32px] text-secondary text-center">
                  {module.content.title}
                </h1>
                <p className="font-primary font-medium text-[18px] text-secondary text-center">
                  {module.content.description}
                </p>
                <img src={module.content.image} alt={module.content.alt} />
              </div>
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </div>
  );
};

export default ModulesDesktop;
