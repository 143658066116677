import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import Lottie from "react-lottie";
import animationData from "../../lotties/sent.json";
import { useNavigate } from "react-router-dom";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function ScheduleSubmitSuccess({
  open,
  handleclose,
  date,
  time,
}: any) {
  //   const [open, setOpen] = useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleclose = () => setOpen(false);
  const navigate = useNavigate();

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="max-w-[768px] mobile:w-screen mx-2 flex flex-col items-center bg-white rounded-[10px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 md:px-24 px-6 py-10 md:py-11 shadow-talentPoolImg">
              <div
                className="absolute right-4 top-4 md:right-9 md:top-9 cursor-pointer"
                onClick={() => navigate("/")}
              >
                <IconContext.Provider
                  value={{ color: "#707070", size: "21px" }}
                >
                  <GrClose />
                </IconContext.Provider>
              </div>
              <div className="w-[200px]">
                <Lottie
                  options={defaultOptions}
                  isClickToPauseDisabled={true}
                />
              </div>
              <h1 className="text-secondary font-font2 font-medium text-2xl md:text-4xl mb-[10px] md:mb-[18px]">
                Awesome!
              </h1>
              <div className="text-center flex flex-col items-center">
                <p className="text-secondary font-primary text-lg mb-4">
                  Thanks for your interest. You will be contacted by our sales
                  team ASAP.
                </p>
                <div className="bg-[#EAEAEA] max-w-[433px] w-full flex justify-between items-center px-4 pt-4 pb-6 md:px-10 md:pt-5 rounded ">
                  <div className="space-y-2 flex flex-col items-start">
                    <p className="font-primary text-secondary">Date</p>
                    <p className="font-primary text-secondary font-bold text-lg">
                      {date}
                    </p>
                  </div>
                  <div className="space-y-2 flex flex-col items-start">
                    <p className="font-primary text-secondary">Time</p>
                    <p className="font-primary text-secondary font-bold text-lg">
                      {time}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
