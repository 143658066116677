import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";

const Enquiry = () => {
  return (
    <div className="mt-[100px] mb-12 md:mt-[126px] md:mb-[63px]">
      <div className="container flex flex-col items-center md:flex-row-reverse md:items-center md:justify-between mobile:space-y-4">
        <div className="space-y-3 max-w-[542px]">
          <h1 className="font-secondary font-extrabold text-secondary text-[27px] md:text-[32px]">
            Need a tailored HR solution? We've got you.
          </h1>
          <p className="text-base font-primary text-[#707070] ">
            All we need are your requirements and we'll build a customized
            version that suits your HR needs
          </p>
          <div>
            <NavLink to="/custom-request">
              <Button name="Let's talk" filled />
            </NavLink>
          </div>
        </div>
        <img
          src="enquiry.svg"
          alt="KlevaHR Custom Human Resource Solutions"
          className=""
        />
      </div>
    </div>
  );
};

export default Enquiry;
