import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

const LandingPage4 = () => {
  return (
    <div>
      <div className="bg-slanted bg-no-repeat bg-cover md:min-h-[750px]">
        <div className="flex items-center flex-col">
          <NavLink to="/">
            <img
              src="./images/KlevaHRlogo.svg"
              className="h-[50px] w-[161px] mt-[4rem] mb-10"
              alt="KlevaHr logo"
            />
          </NavLink>
          <div>
            <span className="font-thin text-[#649AE9] font-secondary mb-4 text-[30px]">
              Essential{" "}
            </span>
            <span className="font-thin  font-secondary text-[30px]">
              HR Modules.
            </span>
          </div>
          <div className="text-center">
            <span className="font-thin text-[#649AE9] font-secondary mb-4 text-[30px]">
              How-to{" "}
            </span>
            <span className="font-thin  font-secondary text-[30px]">
              video mini series.
            </span>
          </div>
          <div className="mb-[40px] text-center">
            <span className="font-normal text-[#649AE9] font-secondary mb-4 text-[30px]">
              Technical support{" "}
            </span>
            <span className="font-normal  font-secondary text-[30px]">
              anytime.
            </span>
          </div>
          <div>
            <img
              src="Laptop.png"
              alt="no img"
              className="md:h-[547px] h-[300px] w-[964px]"
            />
          </div>
        </div>
      </div>
      <div className="flex ml-[1rem] justify-center items-center flex-col">
        <div className="text-[#475569] ml-[1rem] font-normal font-secondary mt-[68px] text-[30px] mb-7">
          In 30 days, KlevaHR gives you access to:
        </div>
        <div className="grid md:gap-x-8 md:gap-y-8 gap-y-5 md:grid-cols-2">
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              <img src="Employeemgt.svg" alt="no img" className=" w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Employee Management - </span>
              Onboard employees, create & manage their profiles with ease
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              <img src="Leave.svg" alt="no img" className=" w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]"> Leave - </span>Apply, relieve,
              approve & track requests with reminders
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              <img src="TimeAttendance.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Time & Attendance - </span>Manage
              work hours, save time & effort with cost control
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              {" "}
              <img src="Timesheets.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Timesheets - </span> Record time
              spent on tasks & evaluate work performance results
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              {" "}
              <img src="Performance.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Performance - </span>Evaluate
              each employee's contribution against set goals
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[100px]">
              {" "}
              <img src="Lifecycle.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Employee Lifecycle - </span>Track
              & optimize employee career progression from hire-to-retire all
              your employee records into a single source of truth
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              {" "}
              <img src="payroll1.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Payroll - </span>Automate your
              payroll with few clicks & generate pay slip on demand
            </span>
          </div>
          <div className=" flex w-[350px] md:w-[500px]">
            <span className=" h-[80px] w-[80px]">
              {" "}
              <img src="reports1.svg" alt="no img" className="w-[80px]" />
            </span>
            <span className="font-normal font-secondary  md:text-[18px] text-[16px]">
              <span className="text-[#649AE9]">Reports - </span> Customize your
              reports from templates & draw insight from analytics
            </span>
          </div>
        </div>
        <NavLink to="/custom-request">
          <div className="my-[48px] flex justify-center font-secondary  md:text-[18px] text-[16px] items-center text-white rounded-3xl bg-[#649AE9] w-[200px] h-[46px] cursor-pointer">
            I'm interested
          </div>
        </NavLink>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage4;
