import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";

const Hero = () => {
  return (
    <div className="bg-heroMobile md:bg-hero bg-no-repeat bg-cover bg-right-top">
      <div className="container flex flex-col items-center justify-between md:flex-row md:pt-[71px] md:pb-[131px]">
        <div className="flex flex-col  max-w-[615px] mobile:mb-12">
          <div className="font-secondary font-bold text-[32px] md:text-[42px] text-secondary">
            Manage your employees with a cloud-based solution that works
          </div>
          <div className="mb-3 text-secondary font-primary font-medium">
            Optimize your workforce performance with automated processes that
            drive engagement and boost productivity
          </div>
          <div className=" flex space-x-4">
            <NavLink to="/free-trial">
              <Button name="Get Started" filled />
            </NavLink>
            <NavLink to="schedule-demo">
              <Button name="Schedule demo" />
            </NavLink>
          </div>
        </div>
        <div>
          <img
            src="heroImage.svg"
            alt="Kleva Human Resource Management"
            className="md:min-w-[450px] mobile:mb-[84px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
