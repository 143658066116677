import FormsNav from "../../components/Forms/FormsNav/FormsNav";
import ScheduleStructure from "../../components/Forms/ScheduleStructure/ScheduleStructure";
import { useSearchParams } from 'react-router-dom';

const ScheduleDemo = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const partner = searchParams.get('partner');

  return (
    <div className="bg-[#F5F8FE] min-h-screen max-h-full">
      <FormsNav />
      <ScheduleStructure partner={partner} />
    </div>
  );
};

export default ScheduleDemo;
