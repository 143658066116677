import React from "react";
import { benefits } from "./shared";

const Benefits = () => {
  return (
    <div className="relative mt-[61px] md:mt-[104px]">
      <div className="container flex flex-col items-center">
        <h1 className="font-secondary font-extrabold text-secondary text-[27px] md:text-[32px] mb-[10px] md:mb-[50px]">
          Why KlevaHR?
        </h1>
        <div className="flex flex-col md:flex-row mobile:space-y-[25px] md:space-x-[25px]">
          {benefits.map((benefit, idx) => (
            <div className="flex items-start space-x-3" key={idx}>
              <img src={benefit.icon} alt={benefit.alt} />
              <div className="flex flex-col">
                <h1 className="font-primary font-extrabold text-secondary text-xl">
                  {benefit.title}
                </h1>
                <p className="text-base font-primary text-[#707070] ">
                  {benefit.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <img
        src="benefitsBg.png"
        alt="background"
        className="-z-10 absolute w-[84px] -bottom-14 md:w-52 md:-bottom-44"
      />
    </div>
  );
};

export default Benefits;
