import React from "react";
import Benefits from "../../components/Benefits/Benefits";
import Enquiry from "../../components/Enquiry/Enquiry";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/Hero/Hero";
import ModulesDesktop from "../../components/Modules/ModulesDesktop";
import ModulesMobile from "../../components/Modules/ModulesMobile";
import NavBar from "../../components/NavBar/NavBar";

const Home = () => {
  return (
    <>
      <NavBar />
      <Hero />
      <div className="bg-modules bg-no-repeat bg-cover bg-bottom md:min-h-[750px]">
        <ModulesMobile />
        <ModulesDesktop />
      </div>
      <Benefits />
      <Enquiry />
      <Footer />
    </>
  );
};

export default Home;
