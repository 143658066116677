import "./App.css";
import FreeTrial from "./pages/FreeTrial/FreeTrial";
import Home from "./pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import CustomRequest from "./pages/CustomRequest/CustomRequest";
import ScheduleDemo from "./pages/ScheduleDemo/ScheduleDemo";
import TermsOfUse from "./pages/Legal/TermsOfUse";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import LandingPage1 from "./pages/LandingPages/LandingPage1";
import LandingPage2 from "./pages/LandingPages/LandingPage2";
import LandingPage3 from "./pages/LandingPages/LandingPage3";
import LandingPage4 from "./pages/LandingPages/LandingPage4";
import SuccessPage from "./pages/SuccessPage";
import FreetrialSuccessPage from "./pages/FreetrialSuccessPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/free-trial" element={<FreeTrial />} />
      <Route path="/custom-request" element={<CustomRequest />} />
      <Route path="/schedule-demo" element={<ScheduleDemo />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/landing-page1" element={<LandingPage1 />} />
      <Route path="/landing-page2" element={<LandingPage2 />} />
      <Route path="/landing-page3" element={<LandingPage3 />} />
      <Route path="/landing-page4" element={<LandingPage4 />} />
      <Route path="/success-page" element={<SuccessPage />} />
      <Route
        path="/free-trial-success-page"
        element={<FreetrialSuccessPage />}
      />
    </Routes>
  );
}

export default App;
