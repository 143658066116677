export const benefits = [
  {
    icon: "remoteAccess.svg",
    title: "Remote access",
    description:
      "No more waiting to get to the office as you can now access your HR work from anywhere",
    alt: "KlevaHR Seamless Remote Access",
  },
  {
    icon: "legalCompliance.svg",
    title: "Legal compliance",
    description:
      "We eliminate manual taxation errors and any compliance delay with regulation updates",
    alt: "KlevaHR Legal Compliance",
  },
  {
    icon: "dataSecurity.svg",
    title: "Data security",
    description:
      "Your employee database is safe and secured on our cloud with military-grade encryption",
    alt: "KlevaHR Data Security",
  },
];
