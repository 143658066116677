import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import Lottie from "react-lottie";
import animationData from "../../lotties/sent.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function SubmitSuccess({ open, handleclose }: any) {
  //   const [open, setOpen] = useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleclose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleclose}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="max-w-[768px]  flex flex-col items-center bg-white rounded-[10px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 md:px-24 px-6 py-10 md:py-11 shadow-talentPoolImg">
              <div
                className="absolute right-4 top-4 md:right-9 md:top-9 cursor-pointer"
                onClick={handleclose}
              >
                <IconContext.Provider
                  value={{ color: "#707070", size: "21px" }}
                >
                  <GrClose />
                </IconContext.Provider>
              </div>
              <div className="w-[200px]">
                <Lottie
                  options={defaultOptions}
                  isClickToPauseDisabled={true}
                />
              </div>
              <h1 className="text-secondary font-font2 font-medium text-2xl md:text-4xl mb-[10px] md:mb-[18px]">
                Awesome!
              </h1>
              <div className="text-center flex flex-col">
                <p className="text-secondary font-primary text-lg mb-4">
                  Thanks for your interest. You will be contacted by our sales
                  team ASAP.
                </p>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
